


























import { namespace } from "vuex-class";

import { Component } from "vue-property-decorator";
import NotFound from "@/views/NotFound.vue";
import CustomerDossier from "@/views/dossier/CustomerDossier.vue";
import EmployeeDossier from "@/views/dossier/EmployeeDossier.vue";
import Page from "@/components/layouts/Page.vue";
import TranslatableComponent, {
  Translatable,
} from "@/lib/mixins/translateable";
import TheHeader from "@/components/TheHeader.vue";

const user = namespace("user");

@Translatable("dossier")
@Component({
  components: { EmployeeDossier, CustomerDossier, NotFound, Page, TheHeader },
  beforeRouteUpdate(to, from, next) {
    (this as Dossier).updateSelectedAccountIds(to.query.selectedAccountIds);
    next();
  },
})
export default class Dossier extends TranslatableComponent {
  @user.Getter("isCustomer")
  private isCustomer!: boolean;

  @user.Getter("isEmployee")
  private isEmployee!: boolean;

  private selectedAccountIds: string[] = [];
  private selectedAccountId: string = "";
  private searchFilter: string = "";

  mounted() {
    this.updateSelectedAccountIds(this.$route.query.selectedAccountIds);
    this.updateSearchFilter(this.$route.query.searchFilter);
  }

  updateSelectedAccountIds(
    newSelection: (string | null)[] | string | undefined
  ) {
    this.selectedAccountIds = newSelection
      ? Array.isArray(newSelection)
        ? (newSelection.filter((value) => value) as string[])
        : [newSelection]
      : [];
    this.selectedAccountId = this.selectedAccountIds
      ? this.selectedAccountIds[0]
      : "";
  }

  updateSearchFilter(newSelection: (string | null)[] | string | undefined) {
    this.searchFilter = newSelection
      ? Array.isArray(newSelection)
        ? newSelection.filter((value) => value)[0] ?? ""
        : newSelection
      : "";
  }
}
