








import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class Page extends Vue {
  @Prop()
  private page!: string | null;
}
