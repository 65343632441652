




















import { Component } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import TranslatableComponent from "@/lib/mixins/translateable";
import TheHeader from "@/components/TheHeader.vue";
import Page from "@/components/layouts/Page.vue";

@Component({
  components: { Page, TheHeader },
})
export default class NotFound extends mixins(TranslatableComponent) {}
